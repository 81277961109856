import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import {
  Layout,
  Seo,
  SideBySide,
  SideImage,
  SideText,
  TextContent,
} from "../../components"
import * as textStyles from "../../components/text-content.module.scss"

export default function Gehoorbescherming({ data }) {
  var sideImage = getImage(data.file)
  var title = "Gehoorbescherming"

  return (
    <Layout site="audio" page={`/producten`}>
      <Seo title={title} shop="audio" />
      <SideBySide>
        <SideImage image={sideImage} />
        <SideText>
          <TextContent title="Preventieve gehoorbescherming">
            <h4>Gehoorbescherming op maat</h4>
            <p className={textStyles.paragraph}>
              Vandaag de dag wordt er gelukkig meer en meer gesensibiliseerd om
              gehoorschade te voorkomen. Vroeger was men zich van geen kwaad
              bewust, dansend naast zware luidsprekers of werkend in hels
              lawaai.
            </p>
            <p className={textStyles.paragraph}>
              Stilaan groeit de bewustwording hoe belangrijk het is om het
              gehoor preventief te beschermen! Tinnitus en gehoorschade door
              lawaai is dezer dagen helaas nog te vaak aanwezig. Wacht daarom
              niet en bescherm je gehoor.
            </p>
            <p className={textStyles.paragraph}>
              De modernste toestellen op de markt zijn nog nauwelijks zichtbaar,
              bieden vele mogelijkheden en een aangenaam draagcomfort!
              Afhankelijk van het gehoorverlies en de levensstijl kan gekozen
              worden voor:
            </p>
            <h4>Voor elke situatie</h4>
            <p className={textStyles.paragraph}>
              Afhankelijk van jouw geluidssituaties en specifieke behoeften,
              adviseren we graag in uw keuze. Niet elke universele
              gehoorbescherming past volledig in je gehoorgang. Naast Universele
              oplossingen, biedt Wim ook gehoorbescherming op maat:
            </p>
            <div className={textStyles.paragraph}>
              <ul>
                <li>Muziekdopjes</li>
                <li>Slaapdopjes</li>
                <li>Zwemdopjes</li>
                <li>Lawaaidopjes</li>
                <li>Zwemdopjes</li>
                <li>Reizen/motorrijden</li>
                <li>Studeren</li>
              </ul>
            </div>
          </TextContent>
        </SideText>
      </SideBySide>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    file(name: { eq: "gehoorbescherming-menu" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, width: 1920)
      }
    }
  }
`
